@import "./variables.scss";

.NavBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;
  background-color: #ffffff;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: $mobile-width) {
  .Layout-article .NavBar {
    display: none;
  }
}

.Layout-background .NavBar {
  background-color: transparent;
}

.NavBar-logo {
  margin-left: 40px;

  @media (max-width: $mobile-width) {
    margin-left: auto;
    margin-right: auto;
  }
}

.NavBar-links {
  position: absolute;
  height: 50px;
  right: 160px;
  top: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile-width) {
    display: none;
  }
}

.NavBar-link,
.NavBar-link-selected {
  border-top: 2px solid rgba(1,1,1,0);
  cursor: pointer;
  font-size: $size-27;
  font-weight: $medium-font;
  padding: 10px 0px;
  margin: 30px;
}

.NavBar-link-selected {
  border-color: #111;
}


/**
 * Mobile nav bar
 */
.NavBarMobile {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $mobile-navbar-width;
  transform: rotate(-90deg);
  position: fixed;
  top: 0;
  left: -100vh;
  transform-origin: right top;
  width: 100vh;
  z-index: $nav-z-index;

  &-logo {
    margin-right: 50px;
  }

  &-menuItem {
    font-size: 18px;
    margin-right: 70px;
    line-height: $mobile-navbar-width;
    cursor: pointer;
  }

  &-header {
    margin-left: 0;
    padding: 20px 0;
  }

  &-library,
  &-about,
  &-results {
    background-color: rgba(255,255,255,0.9);
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 70vw;
    height: 100vh;
    padding-left: $mobile-navbar-width;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-y: scroll;
    z-index: $nav-z-index - 1;
  }

  &-library {
    padding-left: $mobile-navbar-width + 20px;
  }
  
  &-about {
    width: 100vw;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    .AboutSection {
      position: static;
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 100vh;
      
      &-title {
        display: none;
      }
    }
  }

  &-results {
    background-color: #ffffff;
    width: 100vw;
    z-index: $nav-z-index - 2;
  }

  &-searchIcon {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
  }

  &-search {
    .Flyout-search {
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      z-index: $nav-z-index;
      width: 100vw;

      &-results {
        display: block;
      }
    }

    .Flyout-input {
      width: calc(100vw - 40px);
      margin: 0 0;
    }

  }
}