@import "./variables.scss";

.SectionPreview-body {
  margin-top: 5px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  display: flex;
  align-items: stretch;
  a {
    text-decoration: none;
    color: #000000;
  }
}

.SectionPreview-Link {
  display: block;
}

.SectionPreview-title {
  font-size: $size-54;
  line-height: $size-64;
  font-weight: 700;
  position: relative;
  margin: 5vw 6vw 0;
  z-index: 1;
  @media (max-width: $mobile-width) {
    font-size: $size-30;
    line-height: $size-32;
  }
}

.SectionPreview-subtitle {
  font-size: $size-54;
  line-height: $size-64;
  position: relative;
  margin: 0 6vw;
  z-index: 1;
  @media (max-width: $mobile-width) {
    font-size: $size-30;
    line-height: $size-32;
  }
}