@import "./variables.scss";

.ImageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  z-index: $modal-z-index;
  background-position: center;
  background-size: cover;
}

.ImageModal.Fade-enter,
.ImageModal.Fade-enter-done,
.ImageModal.Fade-exit {
  display: flex;
}

div.ImageModal-preview {
  cursor: pointer;
  padding-top: 56.25%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

img.ImageModal-preview {
  cursor: pointer;
  width: 100%;
  padding-bottom: 20px;
}

.ImageModal-image {
  flex: none;
  width: 80vw;
  height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ImageModal-caption {
  font-size: $size-16;
  line-height: $line-height;
  letter-spacing: 0.03em;

  &--bottom {
    color: #ffffff;
    text-align: center;
    position: absolute;
    padding: 20px;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(10vh - 40px);
  }
}

.ImageModal-nav {
  position: absolute;
  top: 50%;
  width: 100vw;
  max-width: auto;
  left: 0;
}

.ImageModal-close {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50px;
  right: 50px;
  cursor: pointer;
  filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, 1));
}

/**
 * Video Modal
 */
.MediaModal {
  width: 100%;
  padding-top: 56.25%;
  position: relative
}

.MediaModal-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MediaModal-overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.MediaModal-play-icon {
  flex: none;
  width: 100px;
  height: 100px;
  filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, 1));
}