$site-width: 1366px;
$navbar-height: 150px;
$modal-z-index: 10;
$nav-z-index: 9;
$default-font: akzidenz-grotesk,sans-serif;
$light-font: 300;
$medium-font: 500;
$bold-font: 700;
$mobile-width: 600px;
$mobile-navbar-width: 55px;

$line-height: 1.4em;

$size-68: 4.25rem;
$size-64: 4rem;
$size-54: 3.375rem;
$size-51: 3.188rem;
$size-36: 2.25rem;
$size-32: 2rem;
$size-30: 1.875rem;
$size-27: 1.688rem;
$size-22: 1.375rem;
$size-20: 1.25rem;
$size-16: 1rem;

:export {
  navbarHeight: $navbar-height;
  mobileWidth: $mobile-width;
}