@import "./variables.scss";

.Article {
  padding-top: $navbar-height;
}
@media (max-width: $mobile-width) {
  .Layout-article .Article {
    padding-top: 0;
    margin-left: $mobile-navbar-width;

    .LoadingBar {
      display: none;
    }
  }
}

.Article-wrapper {
  max-width: $site-width;
  margin: 50px auto;
  padding: 0 5vw;

  @media (max-width: $mobile-width) {
    padding-left: 1vw;
  }
}

.Article-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: $site-width;
  margin: 0 auto;
  padding: 0 5vw;
  min-height: calc(100vh - 100px);
  overflow: auto;
  color: #ffffff;

  @media (max-width: $mobile-width) {
    padding-left: 1vw;
    min-height: 75vw;
  }
}

.Article-cover > .Article-title,
.Article-cover > .Article-subtitle,
.Article-cover > .Article-attribution {
  max-width: 500px;
}

.Article-cover-content {
  background-color: #ffffff;
  overflow: auto;
}

.Article-fullwidth {
  margin: 50px 0;
}

.Article-row {
  display: flex;
  justify-content: space-between;
  @media (max-width: $mobile-width) {
    display: block;
  }
}

.Article-col {
  flex: initial;
  width: calc(50% - 25px);


  .ArticleModule-text {
    width: auto;
  }

  @media (max-width: $mobile-width) {
    width: auto;
  }
}

.Article-title {
  margin: 30px 0;
  font-size: $size-51;
  line-height: $size-54;
  font-weight: bold;
}

.Article-subtitle {
  margin: 30px 0;
  font-size: $size-51;
  line-height: $size-54;
  font-weight: 300;
}

.Article-attribution {
  margin: 30px 0;
  font-size: $size-22;
  line-height: $size-30;
}

.Article-intro {
  font-size: $size-32;
  line-height: $size-36;
}

.Article-content {
  margin: 30px 0;
  font-size: $size-27;
  line-height: $line-height;
}

.ArticleModule-image {
  margin: 50px 0;
}

.ArticleModule-text {
  font-size: $size-27;
  margin: 50px 0;
}

.ArticleModule-text h1,
.ArticleModule-text h2,
.ArticleModule-text h3,
.ArticleModule-text h4,
.ArticleModule-text h5,
.ArticleModule-text h6 {
  line-height: normal;
}

.Article-spacer {
  margin-top: 100px;
}

/**
 * Carousel
 */
.Article-carousel {
  position: relative;

  .carousel__inner-slide {
    display: flex;
    flex-direction: column;
  }

  .ImageModal-preview {
    flex-basis: 100%;
    margin: 0 25px;
    background-color: #f4f4f4;
  }

  .ImageModal-caption {
    padding: 1.5em 20% 0.5em;
    text-align: center;
    font-size: $size-16;
    line-height: 1.6em;
    letter-spacing: 0.03em;
  }
}

.Article-carousel-nav {
  margin: 10px auto;
  padding: 0 10px;
  text-align: right;
  max-width: $site-width;
}

.Article-carousel-image {
  padding-top: 56.25%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 20px;
}

.Article-carousel-back,
.Article-carousel-next {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  background-size: 35% auto;
  background-repeat: no-repeat;
  background-position: center left;
  width: 12%;
  height: 100%;
}

.Article-carousel-back:focus,
.Article-carousel-next:focus {
  outline: none;
}

.Article-carousel-back {
  transform: scaleX(-1);
  right: auto;
  left: 0;
}

.Article-embed {
  text-align: center;
  padding-bottom: 20px;

  &__wrapper {
    text-align: left;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 1%;
}

/**
 * Footer
 */
 .Article-footer {
   display: flow-root;
   background-color: #ffffff;

   &__divider {
     width: 75px;
     border-top: 4px solid #000000;
   }

   &__share {
     margin-top: 20px;
     font-size: 30px;

     > * {
       margin-right: 30px;
     }
   }
 }
/**
 * Resources
 */
.ArticleResources {
  background-color: #f3f2f0;
  padding: 50px;
}

.ArticleResources-content {
  max-width: $site-width;
  margin: auto;
}

.ArticleResource-spacer-first {
  border-top: 1px solid #d6d8d7;
}

.ArticleResource-spacer-first,
.ArticleResource-spacer,
.ArticleResource-item {
  border-bottom: 1px solid #d6d8d7;
  display: flex;
  align-items: center;
  height: 30px;
  text-decoration: none;
  color: #000000;
}

.ArticleResource-icon {
  flex: none;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleResource-name {
  flex: 1;
  font-size: $size-20;
  font-weight: 700;
}

.ArticleResource-description {
  flex: 1;
  font-size: $size-20;
}

.ArticleResource-date {
  flex: 1;
  font-size: $size-20;
}

/**
 * Author
 */
.Author-container {
  background-color: #f3f2f0;
  padding: 50px;
}

.Author-col:first-child {
  flex-basis: calc(66% - 50px);
}

.Author-col:last-child {
  flex-basis: calc(33% - 50px);
}

.Author-content {
  max-width: $site-width;
  margin: auto;
}

.Author-title {
  font-size: $size-32;
  line-height: $size-36;
  font-weight: $bold-font;
}

.Author-attribution,
.Author-intro {
  font-size: $size-22;
  line-height: $size-27;
  margin-top: 1.5em;
}

.Author-intro {
  font-weight: $light-font;
  font-size: $size-20;
  line-height: $size-22;
}

.Author-link a {
  text-decoration: none;
  color: #000000;
}
