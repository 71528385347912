@import "./_variables.scss";

.AboutSection {
  background: linear-gradient(90deg, #6da4d5, #f1efc1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.AboutSection-wrapper {
  max-width: $site-width;
  margin: 50px auto;
  padding: 0 5vw;
}

.AboutSection-spacer {
  margin-top: 50px;
}

.AboutSection-row {
  display: flex;
}

.AboutSection-col:nth-child(1) {
  flex: auto;
  padding-right: 50px;

  @media (max-width: 600px) {
    padding-right: 0;
  }
}

.AboutSection-col:nth-child(2) {
  flex: none;
  width: 200px;

  @media (max-width: 600px) {
    width: auto;
  }
}

.AboutSection-title {
  font-weight: 700;
  font-size: $size-54;
  line-height: $size-64;
}

.AboutSection-content {
  font-size: $size-27;
}

@media (max-width: 600px) {
  .AboutSection-row {
    margin: 50px 0;
    display: block;
  }
}