@import "./variables.scss";
/**
 * AudioPlayer
 */
.AudioPlayer {
  display: flex;
  align-items: center;
  height: 100px;
  width: 80vw;
}

.AudioPlayIcon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  flex: none;
  filter: drop-shadow( 1px 1px 5px rgba(0, 0, 0, 1));
}

/**
 *  AudioPlayer Mini
 */
.AudioPlayerMini {
  display: flex;
  position: absolute;
  top: 0;
  right: 30px;
  z-index: 1;

  .AudioPlayer {
    width: auto;
    height: 150px;
  }

  .AudioPlayIcon {
    margin-right: 10px;
  }

  .AudioBars {
    align-items: center;
  }
}

/**
 * Audio Mute
 */
.AudioBars {
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 40px;
  height: 40px;
}

.AudioBars-bar {
  background-color: #ffffff;
  width: 1px;
  flex: none;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,1);
}

/**
 * Waveform
 */
.AudioWaveform {
  flex: auto;
  position: relative;
  margin: 0 20px;
}

.AudioWaveform-bars {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.AudioWaveform-bar,
.AudioWaveform-bar--active {
  background-color: #ffffff;
  width: 1px;
  flex: none;
  transition: background-color 1s;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,1);
}

.AudioWaveform-bar--active {
  background-color: #6a00ff;
}

.AudioWaveform .scrubber-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.AudioWaveform .bar,
.AudioWaveform .bar__thumb,
.AudioWaveform .bar__progress {
  background-color: transparent;
}

/**
 *  Timestamp
 */
 .MediaTimestamp {
   flex: initial;
   color: #ffffff;
   margin-right: 20px;
   text-align: center;
   width: 60px;
   text-shadow: 1px 1px 5px rgba(0,0,0,1);
 }

 /**
  * VideoPlayer
  */
.VideoPlayer-container {
  position: relative;
}

.VideoPlayer-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.VideoPlayer-controls-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
}

.VideoPlayer-controls {
  display: flex;
  height: 40px;
  align-items: center;
  max-width: $site-width;
  padding: 0 20px;
  margin: auto;
}

.VideoPlayer .scrubber-container {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-position: center;
  flex: auto;
  margin: 0 20px;
  height: 100%;
}

.VideoPlayer .scrubber .bar {
  background-color: transparent;
}

.VideoPlayer .scrubber .bar__thumb,
.VideoPlayer .scrubber .bar__progress {
  background-color: #6a00ff;
}