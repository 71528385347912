@import "css/variables.scss";

html {
  font-size: 16px;

  @media (max-width: $mobile-width) {
    font-size: 10px;
  }
}

body {
  margin: 0;
  font-family: $default-font;
  font-weight: $light-font;
  letter-spacing: 0.05em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Landing-mobile {
  margin-left: $mobile-navbar-width;
}

.Landing-lines {
  position: fixed;
  width: 100vw;
  z-index: 0;
}

.SectionLine {
  position: relative;
  width: 100vw;
  height: 3px;
  margin-bottom: 5px;
  background: linear-gradient(90deg, #6da4d5, #f1efc1, #6da4d5);
  background-size: 200% 100%;
  animation: SectionLineAnimation 10s ease infinite;
  transition: top 2s cubic-bezier(0,1,.4,1);
  overflow: hidden;
}

.LoadingBar {
  position: fixed;
  width: 100%;
  top: $navbar-height;
  left: 0;
}

@keyframes SectionLineAnimation { 
    0%{background-position:200% 0%}
    100%{background-position:0% 0%}
}

.Layout {
  background-image: none;
  transition: background-image 2s cubic-bezier(0,1,.4,1);
}

.Layout-background {
  min-height: 100vh;
}

.Layout-background-image {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  @media (max-width: $mobile-width) {
    background-attachment: scroll;
    height: 80vw;
  }
}

.VideoEmbed-4-3,
.VideoEmbed-16-9 {
  padding-top: 56.25%;
  position: relative;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.VideoEmbed-4-3 {
  padding-top: 75%;
}