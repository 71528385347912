@import "./variables.scss";

.Flyout {
  position: fixed;
  top: 0;
  right: 0;
  width: 70vw;
  height: 100vh;
  display: flex;
  z-index: 4;
  overflow-y: scroll;
}

/**
 * Flyout Clouds
 */

.Flyout-clouds {
  position: fixed;
  top: 0;
  right: 0;
  width: 70vw;
  height: 100vh;
  background-color: #d5ecf9;
  z-index: 1;
}

.Flyout-close {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  right: 0px;
}

.Flyout-left-col {
  width: 50%;
  flex: initial;
  padding: 100px 50px 50px 50px;
}

.Flyout-right-col {
  width: 50%;
  flex: initial;
  padding: 100px 100px 50px 50px;
}

.Flyout-cloud-1,
.Flyout-cloud-2,
.Flyout-cloud-3 {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.5;
  overflow: hidden;
  background-image: url(../images/cloud.png);
}

.Flyout-cloud-1 {
  background-size: 200% auto;
  animation: FlyoutCloudAnimation1 50s linear infinite;
}

.Flyout-cloud-2 {
  transform: scaleX(-1);
  background-size: 300% auto;
  animation: FlyoutCloudAnimation2 50s linear infinite;
}

.Flyout-cloud-3 {
  background-size: 400% auto;
  animation: FlyoutCloudAnimation3 50s linear infinite;
}

@keyframes FlyoutCloudAnimation1 { 
    0%{background-position: 0% 0%}
    100%{background-position: 200% 0%}
}

@keyframes FlyoutCloudAnimation2 { 
    0%{background-position: 0% 0%}
    100%{background-position: -300% 0%}
}

@keyframes FlyoutCloudAnimation3 { 
    0%{background-position: 0% 0%}
    100%{background-position: 400% 0%}
}

/**
 * Flyout
 */

 .Flyout-heading {
   font-weight: 300;
   font-size: $size-22;
 }

/**
 * Flyout sections
 */
 .FlyoutSection:first-child {
   border-top: 2px solid #000000;
 }

 .FlyoutSection {
   border-bottom: 2px solid #000000;
   padding: 20px 0px;
 }

 .FlyoutSection-category {
   cursor: pointer;
 }

 .FlyoutSection-name,
 .FlyoutSection-item,
 .FlyoutSection-item-selected {
   font-weight: 700;
   font-size: $size-22;
   line-height: $size-36;
   margin-left: 10px;
 }

 .FlyoutSection-item,
 .FlyoutSection-item-selected {
   font-weight: 300;
   margin-left: 30px;
   cursor: pointer;
 }

 .FlyoutSection-item-selected {
   font-weight: 700;
 }

 /**
  * Flyout result
  */
.Flyout-heading {
  font-size: $size-22;
  margin-bottom: 50px;
}

.FlyoutResult {
  margin-bottom: 40px;
}

.FlyoutResult a {
  color: #000000;
  text-decoration: none;
}

.FlyoutResult-image {
  width: 100%;
  margin-bottom: 20px;
}

.FlyoutResult-title {
  font-weight: 700;
  font-size: $size-27;
  line-height: $size-30;
}

.FlyoutResult-subtitle {
  font-size: $size-27;
  line-height: $size-30;
}

/**
 * Resource Result
 */
.FlyoutResult {
  .ArticleResource {
    &-item {
      display: block;
      border-bottom: none;
      height: auto;
    }

    &-icon {
      display: inline;
      margin-right: 15px;
    }

    &-name {
      display: inline;
      margin-right: 15px;
    }

    &-description {
      display: inline;
    }

    &-date {
      display: none;
    }
  }
}

/**
 * Search Flyout
 */

.Flyout-search {
  background-color: #f9f8f6;
  display: block;
}

.Flyout-search-count {
  height: 140px;
  line-height: 140px;
  font-size: $size-20;
  text-align: center;
}

.Flyout-input {
  background-color: #f9f8f6;
  display: block;
  margin: 0 50px;
  font-size: $size-54;
  font-weight: 300;
  color: #c8c9cb;
  border-width: 0;
}

.Flyout-input:focus {
  outline: none;
}

.Flyout-input::placeholder {
  font-weight: 300;
  color: #c8c9cb;
}

.Flyout-search-results {
  display: flex;
  flex-wrap: wrap;
  margin: 45px 0 45px 45px;
}

.Flyout-search-result {
  flex: none;
  width: 240px;
  margin-right: 45px;
}